@import "../../Theme.scss";

// https://github.com/fkhadra/react-toastify/blob/master/scss/_variables.scss
$rt-color-info: get-color("primary");
$rt-color-error: get-color("secondary");
$rt-color-warning: get-color("tertiary");


@import "~react-toastify/scss/main";

.Toastify__toast-container, .Toastify__toast-container--top-left{
    margin-top: 50px;
}