@import "../../Theme";

$width: 8em;
$height: 4em;

.menu-wrapper {
  right: 0;
  top: 0;
  position: fixed;
  text-align: right;
  z-index: 1051;
}

.menu-svg {
  width: $width;
}
.menu-svg>path {
  fill: get-color("primary");
}

.menu-content {
  position: absolute;
  top: 0;
  right: 0;
  width: $width * 0.75;
  height: $height * 0.75;
  text-align: center;
}
