@import "../../../../Theme.scss";

$height: 20em;
$width: 15em;

.card {
    background-color: get-color("dark");
    color: white;
    border: solid get-color("tertiary");
    transition: all .5s ease;
}

.card > p {
    transition: all .5s ease;
}

.card.card-md {
    height: 0.7*$height;
    width: 0.7*$width;
    border-radius: 2em;
    border-width: 5px;
    margin: 0.2em;
    padding: 11px;
}
p.card-md {
    font-size: 1.5rem;
}

.card.card-sm {
    height: 0.25*$height;
    width: 0.25*$width;
    border-radius: 1em;
    border-width: 2px;
    margin: 0.1em;
    padding: 5px;
}

p.card-sm {
    font-size: 0.45rem;
}