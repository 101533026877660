@import "../../Theme";

.footer {
  color: white;
  background-color: get-color("dark");
  margin-top: -3em;
  height: 4em;
  justify-content: center;
  align-items: center;
  z-index: 1006;
  position: relative;

  border: 1px solid get-color("primary");
  border-bottom: none;
  border-top-left-radius: 1em;
  border-top-right-radius: 1em;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  padding: 0.5em;
  padding-left: 1em;
  padding-right: 1em;
  text-align: center;
}
