@import "./Theme";
@import "~bootstrap";

html, body, html > * > div.full-height {
  height: 100vh;
}

body, body > * > *, .form-control, .form-control:focus, .list-group-item, .dropdown-menu, .dropdown-header, .dropdown-item, .home-news-text, .jumbotron, .toast, .toast-header, .modal-content, .modal-header .close {
  background-color: get-color("dark") !important;
  color: get-color("bright") !important;
}

a {
  color: get-color("primary");
}

 a:hover {
  color: get-color("primary-shade");
}

.list-group-item {
  border-bottom-color: get-color("bright") !important;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: get-color("primary") !important;
}

.form-control{
  border-color: get-color("primary") !important;
}



.pre-wrap {
  white-space: pre-wrap;
}

.pointer {
  cursor: pointer;
}

.no-user-select {
  user-select: none;
}

.user-select-all {
  user-select: all;
}

.fit-content {
  width: fit-content;
}